import { checkPayment, getPaymentState } from '@/store/slices/stagesSlice/stagesSlice'
import { PaymentStatus } from '@/store/slices/stagesSlice/interfaces'
import PaymentPendingPage from './PaymentPending'
import PaymentSuccessPage from './PaymentSuccess'
import PaymentSuccessByAppPage from './PaymentSuccessByApp'
import PaymentErrorPage from './PaymentError'
import PaymentSubscriptionSuccessPage from './PaymentSubscriptionSuccess'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { RootState } from '@/store'
import PaymentSubscriptionSuccessByAppPage from '@/components/pages/PaymentPage/PaymentSubscriptionSuccessByApp'
import PaymentEventSuccessPage from './PaymentEventSuccessPage'

export default function PaymentResult({ token }: { token: string }) {
  const [status, setStatus] = useState<PaymentStatus>('pending')

  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data } = getPaymentState(reduxState)
  const { paidUntil, area, type, platform, paidUntilEvent } = data
  const dispatch = useDispatch()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const fetchData = async () => {
      await dispatch(
        checkPayment({
          token,
          onSuccess: ({ status }) => {
            setStatus(status)

            if (status === 'pending') {
              timeoutId = setTimeout(() => {
                fetchData()
              }, 5000)
            }
          },
          onError: () => setStatus('error'),
        }),
      )
    }

    fetchData()

    return () => clearTimeout(timeoutId)
  }, [token])

  return (
    <SwitchTransition>
      <CSSTransition key={status} timeout={{ appear: 0, enter: 0, exit: 600 }} classNames="on">
        {status === 'pending' ? (
          <PaymentPendingPage />
        ) : status === 'success' && type === 'Stage' && platform === 'web' ? (
          <PaymentSuccessPage paidUntil={paidUntil} area={area} />
        ) : status === 'success' && type === 'Stage' && platform === 'web_kiosk' ? (
          <PaymentSuccessPage paidUntil={paidUntil} area={area} />
        ) : status === 'success' && type === 'Stage' && platform === 'app' ? (
          <PaymentSuccessByAppPage area={area} paidUntil={paidUntil} />
        ) : status === 'success' && type === 'ClientSubscription' && platform === 'app' ? (
          <PaymentSubscriptionSuccessPage paidUntil={paidUntil} area={area} />
        ) : status === 'success' && type === 'ClientSubscription' && platform === 'web' ? (
          <PaymentSubscriptionSuccessByAppPage paidUntil={paidUntil} area={area} />
        ) : status === 'success' && type === 'ClientEvent' ? (
          <PaymentEventSuccessPage area={area} paidUntilEvent={paidUntilEvent} />
        ) : (
          <PaymentErrorPage area={area} />
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}
